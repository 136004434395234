import axios from 'axios';

const tokenKey = 'tvs.token';

const AppAPIDownloadInstance = axios.create({
  baseURL: `${process.env.REACT_APP_SSP_URL}`,
});

AppAPIDownloadInstance.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

const token = localStorage.getItem(tokenKey);

AppAPIDownloadInstance.interceptors.request.use(
  async (config) => {
    // eslint-disable-next-line no-param-reassign
    config.headers = {
      Authorization: `Bearer ${JSON.parse(token || '')}`,
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

AppAPIDownloadInstance.interceptors.response.use(
  // eslint-disable-next-line consistent-return
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error?.response?.data || error);
  },
);

export default AppAPIDownloadInstance;
