import { EActive } from 'constant/types';
import { get } from 'lodash/fp';
import moment from 'moment';

export enum EValueKeyYards {
  id = 'Id',
  yardName = 'Yard Name',
  yardCode = 'Yard Code',
  branchCode = 'Branch Code',
  branchName = 'Branch Name',
  address = 'Address',
  city = 'City',
  state = 'State',
  zipCode = 'ZIP Code',
  latitude = 'Latitude',
  longitude = 'Longitude',
  emailId = 'Email ID',
  phoneNumber = 'Phone Number',
  documents = 'Documents',
  supervisorName = 'Supervisor Name',
  supervisorId = 'Supervisor ID',
  agencyType = 'Agency Type',
  interfaceFlag = 'Interface Flag',
  status = 'Status',
  lastUpdated = 'Last Updated',
  recordStatus = 'Record Status',
  updatedBy = 'Updated By',
  password = 'Password',
  createdBy = 'Created By',
  interfaceRemarks = 'Interface Remarks',
  createdDate = 'Created Date',
  seizureSource = 'Seizure Source',
  accessProfileCode = 'Access Profile Code',
  yardOwnerName = 'Yard Owner Name',
  ymsOutputMessage = 'YMS Output Message',
}

export const HeaderTable = [
  EValueKeyYards.yardName,
  EValueKeyYards.yardCode,
  EValueKeyYards.branchCode,
  EValueKeyYards.branchName,
  EValueKeyYards.address,
  EValueKeyYards.city,
  EValueKeyYards.state,
  EValueKeyYards.zipCode,
  EValueKeyYards.yardOwnerName,
  EValueKeyYards.emailId,
  EValueKeyYards.phoneNumber,
  EValueKeyYards.documents,
  EValueKeyYards.supervisorName,
  EValueKeyYards.supervisorId,
  EValueKeyYards.agencyType,
  EValueKeyYards.interfaceFlag,
  EValueKeyYards.status,
  EValueKeyYards.lastUpdated,
  EValueKeyYards.updatedBy,
  EValueKeyYards.createdBy,
  EValueKeyYards.createdDate,
];

export type YardMasterUser = {
  branch: string;
  city: string;
  email: string;
  employeeId: string;
  firstName: string;
  id: number;
  lastName: string;
  phoneNumber: string;
};

export function parseMasterUser(data: any): YardMasterUser {
  const branch = get('branch', data);
  const city = get('city', data);
  const email = get('email', data);
  const employeeId = get('employee_id', data);
  const firstName = get('first_name', data);
  const id = get('id', data);
  const lastName = get('last_name', data);
  const phoneNumber = get('phone_number', data);

  return {
    branch,
    city,
    email,
    employeeId,
    firstName,
    id,
    lastName,
    phoneNumber,
  };
}

export interface IYard {
  id: string;
  yardName: string;
  yardCode: string;
  branchCode: string;
  branchName: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  yardOwnerName: string;
  emailId: string;
  phoneNumber: string;
  documents: string;
  supervisorName: string;
  supervisorId: string;
  agencyType: string;
  interfaceFlag: string;
  status: EActive;
  lastUpdated: string;
  updatedBy: string;
  createdBy: string;
  createdDate: string;
  yardMasterUser: YardMasterUser;
  validFrom: string;
  validTo: string;
  agreementFile: string;
  userId: number;
  suspend: string;
  suspendReason: string;
}
export function parseYard(data: any): IYard {
  const id = get('id', data);
  const yardName = get('name', data);
  const yardCode = get('code', data);
  const branchCode = get('branchYard.branchcode', data);
  const branchName = get('branchYard.branchname', data);
  const city = get('city', data);
  const address = get('address', data);
  const state = get('area_name', data);
  const zipCode = get('area_code', data);
  const yardOwnerLastName = get('yardOwner.last_name', data);
  const yardOwnerFirtName = get('yardOwner.first_name', data);
  const emailId = get('yardOwner.email', data);
  const phoneNumber = get('yardOwner.phone_number', data); //
  const documents = get('urlFile', data);
  const supervisorLastName = get('otherInformation.last_name', data); //
  const supervisorFirstName = get('otherInformation.first_name', data); //
  const supervisorId = get('supervisorId', data);
  const userId = get('otherInformation.userId', data);
  const agencyType = get('agencyType', data);
  const interfaceFlag = get('interfaceFlag', data);
  const status = get('status', data);
  const updateAt = get('updatedAt', data);
  const updatedBy = get('updatedBy', data);
  const createdBy = get('createdBy', data);
  const createdAt = get('createdAt', data);
  const validFrom = get('validFrom', data);
  const validTo = get('validTo', data);
  const agreementFile = get('agreementFile', data);
  const suspend = get('suspend', data);
  const suspendReason = get('suspendReason', data);

  // self parse
  const yardOwnerName = yardOwnerLastName || yardOwnerFirtName ? `${yardOwnerLastName} ${yardOwnerFirtName}` : '-';
  const supervisorName =
    supervisorLastName || supervisorFirstName ? `${supervisorLastName} ${supervisorFirstName}` : '-';
  const lastUpdated = moment(updateAt).format('DD/MM/YYYY h:mm A');
  const createdDate = moment(createdAt).format('DD/MM/YYYY h:mm A');
  const yardMasterUser = parseMasterUser(data.yardMasterUser);

  return {
    id,
    yardName,
    yardCode,
    branchCode,
    branchName,
    address,
    city,
    state,
    zipCode,
    yardOwnerName,
    emailId,
    phoneNumber,
    documents,
    supervisorName,
    supervisorId,
    userId,
    agencyType,
    interfaceFlag,
    status,
    lastUpdated,
    updatedBy,
    createdBy,
    createdDate,
    yardMasterUser,
    validFrom,
    validTo,
    agreementFile,
    suspend,
    suspendReason,
  };
}

export const excelHeaderYard = [
  { header: EValueKeyYards.yardName, key: 'yardName' },
  { header: EValueKeyYards.yardCode, key: 'yardCode' },
  { header: EValueKeyYards.branchCode, key: 'branchCode' },
  { header: EValueKeyYards.branchName, key: 'branchName' },
  { header: EValueKeyYards.address, key: 'address' },
  { header: EValueKeyYards.city, key: 'city' },
  { header: EValueKeyYards.state, key: 'state' },
  { header: EValueKeyYards.zipCode, key: 'zipCode' },
  { header: EValueKeyYards.yardOwnerName, key: 'yardOwnerName' },
  { header: EValueKeyYards.emailId, key: 'emailId' },
  { header: EValueKeyYards.phoneNumber, key: 'phoneNumber' },
  { header: EValueKeyYards.documents, key: 'documents' },
  { header: EValueKeyYards.supervisorName, key: 'supervisorName' },
  { header: EValueKeyYards.supervisorId, key: 'supervisorId' },
  { header: EValueKeyYards.agencyType, key: 'agencyType' },
  { header: EValueKeyYards.interfaceFlag, key: 'interfaceFlag' },
  { header: EValueKeyYards.status, key: 'status' },
  { header: EValueKeyYards.lastUpdated, key: 'lastUpdated' },
  { header: EValueKeyYards.updatedBy, key: 'updatedBy' },
  { header: EValueKeyYards.createdBy, key: 'createdBy' },
  { header: EValueKeyYards.createdDate, key: 'createdDate' },
];
export const exportHistory = [
  { header: 'Upload Type', key: 'type' },
  { header: 'No. of Files Processed', key: 'numberFileProcessed' },
  { header: 'Upload Status', key: 'status' },
  { header: 'Total Count', key: 'totalCount' },
  { header: 'Success Count', key: 'successCount' },
  { header: 'Failed Count', key: 'failureCount' },
  { header: 'Uploaded By', key: 'createdByEmail' },
  { header: 'Upload Date', key: 'createdAt' },
];
export interface TYardDetail {
  id: number;
  name: string;
  address: string;
  area_name: string;
  yard_master_user_id: number;
  area_code: string;
  createdBy: string;
  updatedBy: string | null;
  city: string;
  code: number;
  urlFile: string;
  fileName: string;
  supervisorId: number;
  agencyType: string;
  interfaceFlag: string;
  branchId: number;
  createdAt: string;
  updatedAt: string | null;
  validFrom: string;
  validTo: string;
  agreementFile: string;
  otherInformation: {
    suspended: boolean;
    suspend_reason: string;
    id: number;
    employee_id: string;
    first_name: string;
    last_name: string;
    userId: number;
  };
  yardOwner: {
    id: number;
    employee_id: string;
    first_name: string;
    last_name: string;
    phone_number: string;
    country_code: string;
    email: string;
  };
  branchYard: {
    branch_head: string;
    branchcode: string;
    branchname: string;
    contact_person: string;
    createdAt: string;
    created_at: string;
    created_by: string;
    region_code: string;
    region_name: string;
    status: string;
    updatedAt: string;
    updated_at: string;
    updated_by: string;
  };
  yardMasterUser: {
    branch: string;
    city: string | null;
    email: string;
    employee_id: string;
    first_name: string;
    id: number;
    last_name: string | null;
    phone_number: string | null;
  };
}
export interface YardData {
  name: string;
  branchCode: string[] | string;
  address: string;
  city: string;
  area_code: string;
  area_name: string;
  urlFile?: string;
  supervisorId: number;
  code: number;
  yardOwnerId: number;
  status: string;
  fileName?: string;
  agreementFile: string;
  validTo: string;
  validFrom: string;
  suspend: boolean;
  suspendReason: string;
  suspendAction: string;
  id: number;
}
