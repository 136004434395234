/* eslint-disable no-unused-vars */

import { getUserRoles } from 'utils/service/UsersAPI';
import { call, put, takeLatest } from 'redux-saga/effects';
import { showToastError, toastSuccess } from 'constant/types';

import { GET_LIST_ROLES, getListRolesSuccess } from './action';
import { CrudActionRoles } from './types';

function* getListRolesSaga(action: CrudActionRoles) {
  try {
    const res = yield call(getUserRoles);
    if (res.data) {
      action.payload.onSuccess(res.data);
    }
    yield put(getListRolesSuccess(res.data));
  } catch (error) {
    showToastError('get list roles fail');
  }
}

function* rolesSaga() {
  yield takeLatest(GET_LIST_ROLES, getListRolesSaga);
}

export default rolesSaga;
