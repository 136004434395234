import { get } from 'lodash/fp';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import themeColors from 'themes/themeColors';

export enum ACTIVE {
  active = 'Active',
  inactive = 'Inactive',
}
export enum YesOrNo {
  yes = 'Yes',
  no = 'No',
}

export const styles = {
  labelStyle: {
    color: '#000000',
    fontSize: 14,
    fontWeight: 700,
  },
};

export const renderData: (value: string | null | undefined) => string = (value) => {
  return value || '-';
};

export const Container = styled.div`
  background-color: ${themeColors.White};
  border-radius: 8px;
  padding: 24px;
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export enum SPACE {
  SPACE_10 = 10,
  SPACE_20 = 20,
  SPACE_24 = 24,
  SPACE_30 = 30,
  SPACE_32 = 32,
  SPACE_40 = 40,
  SPACE_48 = 48,
  SPACE_50 = 50,
  SPACE_60 = 60,
  SPACE_70 = 70,
  SPACE_80 = 80,
  SPACE_90 = 90,
  SPACE_100 = 100,
  SPACE_120 = 120,
  SPACE_140 = 140,
}

export enum EActive {
  Active = 'Active',
  Inactive = 'Inactive',
}
export type THistory = {
  last_updated?: string;
  updated_by?: string;
  created_by?: string;
  created_date?: string;
  createdAt?: string;
  updatedAt?: string;
};
export const MappingColor = (ActiveName: EActive) => {
  switch (ActiveName) {
    case EActive.Active:
      return themeColors.Success;
    case EActive.Inactive:
      return themeColors.Error;
    default:
      return '';
  }
};

export type ListHistory = {
  id: number;
  type: string;
  uploadId: string;
  uploadType: string;
  status: string;
  totalCount: number;
  successCount: number;
  failureCount: number;
  activeAccount: boolean;
  createdAt: string;
  updatedAt: string;
  createdByEmail: string;
  numberFileProcessed: number;
  createdByEmployId: string;
  createdById: string;
};

export function parseDataHistory(data: any): ListHistory {
  const id = get('id', data);
  const type = get('type', data);
  const uploadId = get('upload_id', data);
  const uploadType = get('upload_type', data);
  const status = get('status', data);
  const totalCount = get('total_count', data);
  const successCount = get('success_count', data);
  const failureCount = get('failure_count', data);
  const activeAccount = get('active_account', data);
  const createdAt = get('createdAt', data);
  const updatedAt = get('updatedAt', data);
  const createdByEmail = get('createdBy.email', data);
  const createdByEmployId = get('createdBy.employee_id', data);
  const numberFileProcessed = get('number_file_processed', data);
  const createdById = get('createdBy.id', data);
  return {
    id,
    type,
    uploadId,
    uploadType,
    status,
    totalCount,
    successCount,
    failureCount,
    activeAccount,
    createdAt,
    updatedAt,
    createdByEmail,
    createdByEmployId,
    createdById,
    numberFileProcessed,
  };
}

export const styleTextTable = {
  fontSize: 14,
  fontWeight: 700,
  color: themeColors.Primary,
  cursor: 'pointer',
};
export type MetaData = {
  total_count?: number;
  per_page?: number;
};

export function getPanigation(size: number) {
  const pageSize = size === 0 ? 1 : Math.ceil(size / SPACE.SPACE_10);
  return pageSize;
}

export const resetH1 = { margin: 0, padding: 0 };

export const Active = [ACTIVE.active, ACTIVE.inactive];

export const TextNoResult = styled.p`
  width: 78.8%;
  padding: 10px;
  font-size: 16px;
  font-weight: 700;
  background-color: #fff;
  position: absolute;
  text-align: center;
`;

export const toastSuccess = (message: string) => {
  toast.success(message, {
    position: 'top-center',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'colored',
  });
};

export const showToastError = (content: string) => {
  toast.error(content, {
    position: 'top-center',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'colored',
  });
};

export const TextStatus = styled.span<{
  status: EActive;
}>`
  color: ${(props) => MappingColor(props.status)};
`;
