/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/indent */
// @ts-ignore
// eslint-disable-next-line import/extensions
import { defaults } from './defaultConfigs';

export const Localization = {
    ...defaults,
    assignedCs: 'Assigned FC',
    csId: 'FC ID',
    currencySymbol: '₹',
    currencyAbbrev: 'INR',
    branch: 'Locations',
    accountBranch: 'Branch',
    branches: 'Branches',
    collectionSpecialist: 'Field Collectors',
    principalPayment: 'Outstanding Payment*',
    showPenaltyPayment: true,
    penaltyPayment: 'Charges Received',
    csLeaderBoard: 'CS Leaderboard',
    branchLeaderBoard: 'Branch Leaderboard',
    collectionsBarChartTitle: 'Field Collections',
    accountsBarChartTitle: 'Field Accounts',
    collectionSpecialistPerformanceTitle: 'Field Collectors Performance',
    branchListingHeader: 'Locations',
    branchListingBreadcrumb: 'Locations',
    csListingHeader: 'Field Collectors',
    csListingBreadcrumb: 'Field Collectors',
    csDetailsBreadcrumb: 'Specialist',
    logoFilename: 'Logo_TVSCredit.png',
    clientName: 'TVSC',
    navbarBackgroundColor: '#34276E',
    showManuals: true,
    showAIManuals: false,
    showAPK: false,
    csManualLink:
        'https://tvsd-assets.s3-ap-southeast-1.amazonaws.com/artha/manuals/TVSC_CS_UserManual.pdf',
    cashierManualLink: 'https://tvsd-assets.s3-ap-southeast-1.amazonaws.com/artha/manuals/Generic_Cashier_UserManual.pdf',
    managerManualLink: 'https://tvsd-assets.s3-ap-southeast-1.amazonaws.com/artha/manuals/TVSC_Manager_UserManual.pdf',
    aiManualLink: 'https://tvsd-assets.s3-ap-southeast-1.amazonaws.com/artha/manuals/TVSC_AI_Itinerary.pdf',
    apkLink: process.env.APK_URL || '',
    eulaTextUrl: 'https://tvsd-assets.s3.ap-southeast-1.amazonaws.com/eula/EULA_TVSC_mobile.txt',
    eulaVersionUrl: 'https://tvsd-assets.s3.ap-southeast-1.amazonaws.com/eula/EULA_TVSC_mobile.json',
    privacyStatementUrl:
        'https://tvsd-assets.s3.ap-southeast-1.amazonaws.com/privacy-statement/mobile_application_privacy_statement.txt',
    privacyStatementVersion:
        'https://tvsd-assets.s3.ap-southeast-1.amazonaws.com/privacy-statement/mobile_application_privacy_statement.json',
    zipcodeLength: 6,
    zipcodeValidationMsg: 'India zipcode should have 6 characters. Ex: 744202, 683544',
    country: 'India',
    countryAbbrev: 'IN',
    transactionListingHeader: 'Transaction Listing',
    transactionListingBreadcrumb: 'Transaction Listing',
    activateTransactionListing: true,
    activateCreditDebitMemo: true,
    activatePPD: false,
    currencyLocale: 'en-IN',
    hiddenPenaltyField: true,
    csReportDownload: false,
    cashierReportDownload: true,
    appId: '1:834347563222:web:a0b0e7503490cdd69ab177',
    measurementId: 'G-ZZX8XK1RYR',
    apiKey: 'AIzaSyCjJ4qq0cRwMo5RLPDTbgGPVY1b8IfnvBI',
    showTranslation: false,
    recoveredAccountsLabel: 'RECOVERED ACCOUNTS',
    noOfPaymentsLabel: 'NO. OF RECORDS',
    showCashierPage: false,
    displayClientCode: false,
    metricsToDisplay: ['mtd-collection-efficiency', 'account-recovery-rate', 'average-visits-per-day'],
    cspStatsToHide: ['total_skipped_visits', 'itinerary_non_compliance', 'visit_per_day'],
    showSummaryReportDownload: true,
    collectionSpecialistItinerary: 'Field Collector Itinerary',
    ipAddressUrlOne: 'https://api.ipify.org/?format=json',
    ipAddressUrlTwo: 'https://api.bigdatacloud.net/data/client-ip',
    employeeID: true,
};
