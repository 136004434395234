import { all, fork } from 'redux-saga/effects';

import authSaga from 'redux/auth/saga';
import crudSaga from 'redux/crud/saga';
import assetsSaga from './assets/saga';
import applicationSaga from './application/saga';
import yardSaga from './yard/saga';
import rolesSaga from './roles/saga';
import agencyUserSaga from './agencyUser/saga';

export default function* rootSaga() {
  yield all([
    fork(authSaga),
    fork(crudSaga),
    fork(assetsSaga),
    fork(applicationSaga),
    fork(yardSaga),
    fork(rolesSaga),
    fork(agencyUserSaga),
  ]);
}
