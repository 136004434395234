import React, { useEffect, useState } from 'react';

const Today = ({ size }) => {
  return (
    <svg width="83" height="112" viewBox="0 0 83 112" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.86636 21.9875H7.99678V21.8547C8.08862 19.3808 11.6388 19.3827 11.7298 21.8547V21.9875H27.989V21.8547C28.0808 19.3808 31.631 19.3827 31.722 21.8547V21.9875H37.8523C38.8831 21.9875 39.7188 22.8231 39.7188 23.8539V34.8495C39.6269 37.3234 36.0767 37.3215 35.9858 34.8495V33.7172H3.73285V55.9741H16.8605C19.3343 56.0659 19.3325 59.6161 16.8605 59.7071H1.86636C0.835564 59.7071 -0.000125885 58.8714 -0.000125885 57.8406V23.8539C-0.000125885 22.8231 0.835564 21.9875 1.86636 21.9875ZM35.9858 29.9842V25.7204H31.7218V25.8531C31.63 28.3269 28.0798 28.3251 27.9889 25.8531V25.7204H11.7296V25.8531C11.6378 28.3269 8.08762 28.3251 7.99666 25.8531V25.7204H3.73285V29.9844H35.9858V29.9842Z"
        fill="#B1B6FF"
      />
      <path
        d="M63.6698 40.0088C60.7115 40.0088 58.3047 42.4156 58.3047 45.3739V58.019C58.3965 60.4929 61.9467 60.491 62.0377 58.019V54.0206C62.12 51.8579 65.2204 51.8594 65.3019 54.0206V58.019C65.3937 60.4929 68.9439 60.491 69.0349 58.019V45.3738C69.0349 42.4156 66.6282 40.0088 63.6698 40.0088ZM65.3019 48.9099C64.2816 48.5726 63.0579 48.5726 62.0377 48.9099V45.3738C62.12 43.2111 65.2204 43.2126 65.3019 45.3738V48.9099Z"
        fill="#B1B6FF"
      />
      <path
        d="M28.1875 40.1582H20.1906C17.7168 40.25 17.7186 43.8002 20.1906 43.8912H22.3226V58.0184C22.4145 60.4922 25.9647 60.4904 26.0556 58.0184V43.8912H28.1875C30.6612 43.7993 30.6594 40.249 28.1875 40.1582Z"
        fill="#B1B6FF"
      />
      <path
        d="M49.9274 40.1582H45.929C44.8982 40.1582 44.0625 40.9939 44.0625 42.0247V58.0184C44.0625 59.0492 44.8982 59.8849 45.929 59.8849H49.9274C53.1613 59.8849 55.7924 57.2539 55.7924 54.02V46.0231C55.7924 42.7892 53.1613 40.1582 49.9274 40.1582ZM52.0594 54.02C52.0594 55.1955 51.1029 56.1519 49.9274 56.1519H47.7955V43.8912H49.9274C51.1029 43.8912 52.0594 44.8476 52.0594 46.0231V54.02Z"
        fill="#B1B6FF"
      />
      <path
        d="M36.1853 40.0088C33.2271 40.0088 30.8203 42.4156 30.8203 45.3739V54.6703C31.091 61.7799 41.2825 61.7744 41.5505 54.6703V45.3739C41.5505 42.4156 39.1437 40.0088 36.1853 40.0088ZM37.8175 54.6702C37.7352 56.8329 34.6348 56.8314 34.5533 54.6702V45.3738C34.6357 43.2109 37.736 43.2127 37.8175 45.3738V54.6702Z"
        fill="#B1B6FF"
      />
      <path
        d="M78.5911 57.8407V50.2842L82.3925 42.6815C83.4169 40.4279 80.2408 38.8418 79.0538 41.012L76.7246 45.67L74.3956 41.012C73.9347 40.09 72.8136 39.7162 71.8915 40.1773C70.9695 40.6384 70.5958 41.7595 71.0569 42.6815L74.8581 50.2842V57.8407C74.9499 60.3145 78.5002 60.3126 78.5911 57.8407Z"
        fill="#B1B6FF"
      />
    </svg>
  );
};

export default Today;
