import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import { Container, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Nav, NavItem, Button } from 'reactstrap';
import styles from 'styled-components';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LanguageIcon from '@mui/icons-material/Language';
import { IconButton, Typography, Input } from '@material-ui/core';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import i18next from 'i18next';
import PDFViewer from 'pdf-viewer-reactjs';
import SearchIcon from '@mui/icons-material/Search';
import { PdfNavBar } from 'components/elements';
import { TvsLogout, TvsDownload } from 'components/icons';
import { isSideBarMenuUsers } from 'helpers';
import useScreenSize from 'utils/screenSize';
import { clearActionStatus, fetchCollection } from 'redux/crud/action';
import authProvider from 'utils/authProvider';
import { userLoad, userLogout } from 'redux/auth/actions';
import { BORROWER_ROLE, CASHIER_ROLE, DEALER_ROLE, SUPERUSER_ROLE, Localization } from 'constant';
import ArrowLeft from 'components/icons/arrowLeft';
import ArrowRight from 'components/icons/arrowRight';
import ModalPortal from 'components/elements/ModalPortal';
import NotificationBadge from 'containers/Notification/notification-badge';
import ActivitiLog from 'constant/svgIcons/ActivitiLog';

const Header = ({ onClickMenu }: any) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dispatch = useDispatch();
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const { auth } = useSelector((state: any) => state);
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState<string>();
  const [anchorEl, setAnchorEl] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [userManual, setUserManual] = useState<string>();

  const open = Boolean(anchorEl);

  const handleManual = () => {
    setShowModal(true);
  };

  const handleLogout = () => {
    authProvider.logout();
    dispatch(clearActionStatus('userTc'));

    // clear user info and reset notifications
    dispatch(userLogout());
    dispatch(
      fetchCollection('notifications', 'cms/non-sensitive-notifications', {
        filter: {
          client: Localization.clientName,
        },
      }),
    );
    history.push('/login');
  };

  const languages = [
    {
      code: 'bn',
      name: 'বাংলা (Bangla)',
    },
    {
      code: 'en',
      name: 'English',
    },
    {
      code: 'es',
      name: 'Español (Spanish)',
    },
  ];

  const getFullLanguageName = (lng: string) => {
    switch (lng) {
      case 'en':
        return 'English';
      case 'ne':
        return 'Nepali';
      case 'bn':
        return 'বাংলা (Bangla)';
      case 'es':
        return 'Español (Spanish)';
      default:
        return 'English';
    }
  };

  useEffect(() => {
    setLanguage(getFullLanguageName(i18n.language));
  }, [i18n.language]);

  useEffect(() => {
    // getPDF();
    if (auth?.token) {
      setAnchorEl(null);
      dispatch(userLoad());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (auth?.user?.role) {
      setUserManual(
        auth?.user?.role === CASHIER_ROLE ? Localization.cashierManualLink : Localization.managerManualLink,
      );
    }
    if (!auth.token) {
      history.push('/login');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  const route = useRouteMatch();
  const screen = useScreenSize();

  const handleEnglish = () => {
    changeLanguage('en');
    setAnchorEl(null);
  };

  const handleNepali = () => {
    changeLanguage('ne');
    setAnchorEl(null);
  };

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  const handleClick = (event) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [maxPages, setMaxPages] = useState<number>(0);
  const getMax = (e: number) => {
    setTimeout(() => {
      setMaxPages(e);
    }, 0);
  };

  return (
    <>
      <ModalPortal visible={showModal} setVisible={setShowModal} closeOnClickOutside={true}>
        <div style={{ justifyContent: 'center', alignSelf: 'center', flex: 1 }}>
          {maxPages ? (
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(20, 1fr)',
                paddingLeft: 24,
                paddingRight: 24,
                paddingTop: 16,
                paddingBottom: 16,
                backgroundColor: '#F2F3FF',
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
              }}
            >
              <div>
                <InputStyled
                  style={{
                    gridColumn: 1,
                    borderWidth: 1,
                    borderRadius: 10,
                    borderColor: 'gray',
                    borderStyle: 'solid',
                    backgroundColor: 'white',
                    padding: 5,
                    width: 35,
                  }}
                  placeholder={`${pageNumber}`}
                  value={pageNumber}
                  onChange={(e) => {
                    e.preventDefault();
                    if (+e.target.value <= maxPages && +e.target.value >= 0) setPageNumber(+e.target.value);
                  }}
                />
              </div>
              <div
                style={{
                  gridColumn: 2,
                  fontSize: 14,
                  whiteSpace: 'nowrap',
                  alignSelf: 'center',
                  fontWeight: '700',
                  marginLeft: 0,
                  marginRight: 10,
                }}
              >
                / {maxPages}
              </div>
              <div
                role="button"
                onClick={() => setPageNumber((prev) => (prev > 1 ? prev - 1 : prev))}
                onKeyDown={() => setPageNumber((prev) => prev - 1)}
                tabIndex={0}
                style={{
                  gridColumn: 3,
                  borderRadius: 10,
                  backgroundColor: '#6956BA',
                  alignItems: 'center',
                  alignContent: 'center',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  padding: 6,
                  alignSelf: 'center',
                  marginRight: 10,
                }}
              >
                <ArrowLeft style={{ alignSelf: 'center' }} />
                <div
                  style={{
                    color: 'white',
                    alignSelf: 'center',
                    textAlign: 'center',
                    marginRight: 5,
                    fontWeight: '700',
                  }}
                >
                  Prev
                </div>
              </div>

              <div
                role="button"
                onClick={() => setPageNumber((prev) => (prev < maxPages ? prev + 1 : prev))}
                onKeyDown={() => setPageNumber((prev) => prev + 1)}
                tabIndex={0}
                style={{
                  gridColumn: 4,
                  borderRadius: 10,
                  backgroundColor: '#6956BA',
                  alignItems: 'center',
                  alignContent: 'center',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  padding: 6,
                  alignSelf: 'center',
                }}
              >
                <div
                  style={{
                    color: 'white',
                    alignSelf: 'center',
                    textAlign: 'center',
                    fontWeight: '700',
                    marginLeft: 5,
                  }}
                >
                  Next
                </div>
                <ArrowRight style={{ alignSelf: 'center' }} />
              </div>
            </div>
          ) : null}

          {userManual && (
            <PDFViewer
              document={{
                url:
                  auth?.user?.role === CASHIER_ROLE ? Localization.cashierManualLink : Localization.managerManualLink,
              }}
              hideRotation={true}
              hideZoom={true}
              navbarOnTop={true}
              externalInput={true}
              page={pageNumber}
              getMaxPageCount={getMax}
              navigation={PdfNavBar}
              canvasCss={{ justifyContent: 'center', alignSelf: 'center', flex: 1 }}
            />
          )}
        </div>
      </ModalPortal>
      <Div>
        <Container fluid={isSideBarMenuUsers(auth?.user?.role)}>
          <div className="d-flex justify-content-between">
            <div className="d-flex justify-content-center">
              {isSideBarMenuUsers(auth?.user?.role) && ['m', 's', 'xs'].includes(screen.screen) && (
                <ButtonMenu onClick={onClickMenu} />
              )}
              {(auth?.user?.role === DEALER_ROLE || auth?.user?.role === SUPERUSER_ROLE) && (
                <Link to="/">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/${Localization.logoFilename}`}
                    alt={Localization.clientName}
                    height="45"
                  />
                </Link>
              )}
            </div>
            {auth?.user?.role === BORROWER_ROLE && (
              <Nav>
                <NavItem className={route?.path === '/' ? 'active' : ''}>
                  <LinkStyle to="/">Dashboard</LinkStyle>
                </NavItem>
                <NavItem className={route?.path.indexOf('daily-collections') > -1 ? 'active' : ''}>
                  <LinkStyle to="/daily-collections">Daily Collections</LinkStyle>
                </NavItem>
                {auth?.user?.role === 'C' && (
                  <NavItem className={route?.path.indexOf('branches') > -1 ? 'active' : ''}>
                    <LinkStyle to="/branches">{Localization.branch}</LinkStyle>
                  </NavItem>
                )}
                <NavItem className={route?.path.indexOf('specialist') > -1 ? 'active' : ''}>
                  <LinkStyle to="/specialist">{Localization.collectionSpecialist}</LinkStyle>
                </NavItem>
                <NavItem className={route?.path.indexOf('cashier') > -1 ? 'active' : ''}>
                  <LinkStyle to="/cashiers">Cashier</LinkStyle>
                </NavItem>
                <NavItem className={route?.path.indexOf('account') > -1 ? 'active' : ''}>
                  <LinkStyle to="/accounts">Accounts</LinkStyle>
                </NavItem>
              </Nav>
            )}
            <div className="d-flex justify-content-center">
              <NotificationBadge />
              <span className="d-flex align-items-center mr-2">
                <Link to="/activity-log">
                  <ActivitiLog />
                </Link>
              </span>
              {auth?.user?.role === DEALER_ROLE && (
                <>
                  <IconButton
                    color="inherit"
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                  >
                    <LanguageIcon sx={{ display: { sxs: 'none', lg: 'block' }, marginRight: '3px', color: 'white' }} />
                    <div>
                      <Typography component="h6" variant="body1" style={{ color: 'white' }}>
                        {language}
                      </Typography>
                    </div>
                    <KeyboardArrowDownIcon sx={{ color: 'white' }} />
                  </IconButton>
                  <MenuStyle
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClick={handleClose}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >
                    <MenuItemStyle
                      style={{
                        color: 'white',
                        display: 'flex',
                        paddingTop: '6px',
                        paddingBottom: '6px',
                        paddingLeft: '16px',
                        paddingRight: '16px',
                        justifyContent: 'start',
                      }}
                      divider={true}
                      value="en"
                      onClick={handleEnglish}
                    >
                      English
                    </MenuItemStyle>
                    <MenuItemStyle
                      style={{
                        color: 'white',
                        display: 'flex',
                        paddingTop: '6px',
                        paddingBottom: '6px',
                        paddingLeft: '16px',
                        paddingRight: '16px',
                        justifyContent: 'start',
                      }}
                      value="bn"
                      onClick={handleNepali}
                    >
                      नेपाली (Nepali)
                    </MenuItemStyle>
                  </MenuStyle>
                </>
              )}
              {auth?.user?.role !== DEALER_ROLE && (
                <>
                  {Localization.showTranslation && (
                    <IconButton
                      color="inherit"
                      id="basic-button"
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      onClick={handleClick}
                    >
                      <LanguageIcon
                        sx={{ display: { sxs: 'none', lg: 'block' }, marginRight: '0px', color: 'white' }}
                      />
                    </IconButton>
                  )}
                  <MenuStyle
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClick={handleClose}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >
                    {languages.map(({ code, name }) => (
                      <MenuItemStyle
                        key={code}
                        value={code}
                        style={{
                          color: 'black',
                          display: 'flex',
                          paddingTop: '6px',
                          paddingBottom: '6px',
                          paddingLeft: '16px',
                          paddingRight: '16px',
                          justifyContent: 'start',
                        }}
                        onClick={() => {
                          i18next.changeLanguage(code);
                        }}
                      >
                        {name}
                      </MenuItemStyle>
                    ))}
                  </MenuStyle>
                </>
              )}
              {auth?.user?.role !== DEALER_ROLE && (
                <>
                  <div className="d-flex justify-content-center">
                    <DropdownStyle isOpen={dropdownOpen} toggle={toggle}>
                      <DropdownToggleType>
                        <div className="d-flex align-items-center">
                          <img
                            src={`${process.env.PUBLIC_URL}/images/user_profile.svg`}
                            alt="avatar"
                            style={{ marginRight: '10px' }}
                          />
                          {auth?.user?.first_name} {auth?.user?.last_name}
                          <div className={!dropdownOpen ? 'pb-1' : ''}>
                            {!dropdownOpen ? (
                              <div className="ml-2 header-arrow-down" />
                            ) : (
                              <div className="ml-2 header-arrow-up" />
                            )}
                          </div>
                        </div>
                      </DropdownToggleType>
                      <DropdownMenuStyle right container="body">
                        {Localization.showManuals && (
                          <DropdownItem onClick={handleManual}>
                            <TvsDownload size={28} />
                            {t('User Manual')}
                          </DropdownItem>
                        )}
                        <DropdownItem onClick={handleLogout}>
                          <TvsLogout size={28} />
                          {t('Logout')}
                        </DropdownItem>
                      </DropdownMenuStyle>
                    </DropdownStyle>
                  </div>
                </>
              )}
            </div>
          </div>
        </Container>
      </Div>
    </>
  );
};

const InputStyled = styles.input`
  border: none;
  &: focus {
    border: none;
    outline: none;
  }
`;

const MenuStyle = styled(Menu)`
  & .MuiMenu-list {
    // background: rgba(42, 42, 62, 1);
    background: white;
  }
`;

const MenuItemStyle = styled(MenuItem)`
  // background: rgba(42, 42, 62, 1);
  background: white;
  align-items: start;
  &:hover {
    // background: rgba(105, 86, 186, 1);
    background: rgba(243, 243, 254, 1);
  }
`;

const ButtonMenu = styled(Button)`
  &:after {
    clear: both;
    display: block;
    content: '';
    background: url(${process.env.PUBLIC_URL}/images/hamburger.svg);
    background-repeat: no-repeat;
    width: 26px;
    height: 19px;
  }
  background-color: transparent;
  z-index: 1;
  border: none;
  margin-right: 20px;
  padding: 8px;
  &:hover,
  &:focus,
  &:visited,
  &:active {
    border: none !important;
    background: rgba(255, 255, 255, 0.3) !important;
    border: none;
    box-shadow: none !important;
    outline: 0;
  }
`;

const LinkStyle = styled(Link)`
  margin-right: 30px;
  color: #fff;
`;

const DropdownMenuStyle = styled(DropdownMenu)``;

const Div = styles.div`
  background: ${Localization.navbarBackgroundColor};
  padding: 5px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
`;
const DropdownStyle = styled(Dropdown)`
  font-weight: 400;
  &:hover,
  &:focus,
  &:visited {
    background: transparent;
    border: none;
    box-shadow: none;
    outline: 0;
  }
`;
const DropdownToggleType = styled(DropdownToggle)`
  background: transparent;
  border: none;
  border-radius: 0;
  font-family: 'Roboto', sans-serif;
  &:hover,
  &:focus,
  &:visited,
  &:active {
    border: none !important;
    background: rgba(255, 255, 255, 0.3) !important;
    border: none;
    box-shadow: none !important;
    outline: 0;
  }
`;

export default Header;
